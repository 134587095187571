  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/1-1-1-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expert-construction/'>L'expert construction</a></li><li>Role</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le rôle de l’Expert Construction</h1>

<p>L’Expert construction a pour objectif de vous assister dans vos problématiques de gestion de sinistre. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Role" />
</div>

<p>Il vérifie la conformité de l’ouvrage aux règles de l’Art et aux documents techniques unifiés (DTU).</p>
<p>Il joue également un rôle de médiateur et de conciliateur afin de résoudre les conflits opposant, par exemple, un Maître d’Ouvrage (vous) et une entreprise ou l’assurance.</p>

<p>En définitive, il vous apporte une assistance technique et joue un rôle de conciliateur pour résoudre les conflits en amiable.</p>

<p>Si le recours amiable ne porte pas ses fruits, il apporte son assistance technique précieuse lors d’expertise judiciaire, afin d’apporter des dires techniques de qualité à l’avocat. </p>

<h2>L’Expert construction RV Expertises - comment se déroule une expertise ? </h2>

<p>L’Expert construction analyse préalablement les plans de l’ouvrage, l’environnement, mais aussi également vos garanties contractuelles. </p>

<p>Lors de sa visite technique, le rôle de l’Expert construction est d’inspecter rigoureusement tous les aspects de l’ouvrage et de vérifier la conformité des exécutions avec les plans et contrats qui engagent la responsabilité des parties : </p>

<ul><li>gros oeuvre,</li>
<li>second oeuvre,</li>
<li>menuiseries,</li>
<li>revêtement,</li>
<li>toiture, charpente,</li>
<li>humidité, infiltrations.</li></ul>

<p>Il répertorie par la suite dans son rapport tous les désordres, malfaçons ou autres éléments non conformes et constatés. </p>
<p>Les désordres sont analysés en fonction des DTU en vigueur et s'appliquant au domaine d’Expertise.</p>

<p>Il peut également demander des investigations complémentaires par des entreprises spécialisées ou demander l’intervention d’un <strong>sapiteur</strong>.</p>

<p>Il estime le coût des travaux de réparation et de remise en état.</p>

<p>Enfin il peut engager la responsabilité d’une partie ou déterminer si un aléa naturel exceptionnel est le facteur déterminant des désordres (ex : sécheresse, inondation..)</p>
<h2>Le rôle de l’Expert construction, c’est aussi…</h2>
<ul><li>d’apporter conseils et assistance,</li>
<li>de vous orienter sur les travaux de reprise adaptés à votre problématique,</li>
<li>établir un chiffrage estimatif des travaux</li>
<li>établir un rapport technique détaillé clair et concis,</li>
<li>vérifier la conformité des travaux. </li></ul>

<h2>Le cabinet RV EXPERTISES s’engage </h2>

<ul><li>ÉCOUTE : Afin que votre expertise réalisée de façon rigoureuse et pour répondre parfaitement à votre problématique, nous définissons avec vous les contours et le contexte de votre demande</li>
<li>DISPONIBILITÉ : Nous répondons à vos demandes d’urgence afin de répondre au mieux à vos délais</li>
<li>CLARTÉ : A la fin des investigations techniques de terrain nous compilons les éléments techniques recueillis in situ avec l’étude des documents fournis afin de produire une analyse fine de votre dossier. Nos analyses et expertises s’appuient sur les Documents Techniques Unifiées (DTU), les techniques de l’ingénieur (reprises des charges, calcul des fleches, vérification au glissement etc..) afin d’établir le lien entre pathologies désordres et causes de ces derniers. L’objectif est ici la traduction d’analyses complexes en une synthèse claire, explicite et interprétable aussi bien par un non sachant que pour un professionnel.</li>
<li>RÉACTIVITÉ : Nous nous engageons à vous remettre un rapport dans un délais convenable afin que vous puissiez avancer dans vos démarches.</li></ul>




<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contactez un expert construction</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expert-construction/role/' className='active'>Role</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details